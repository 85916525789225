<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Value Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- fundType search -->
      <div class='d-flex align-items-center'>
        <h5 class='mb-0 line-height-36px'>Load</h5>
        <button mat-button [disableRipple]="true" class='text-pumpkin qm-text-lg font-weight-bolder mx-1'
                [matMenuTriggerFor]="fundTypes">
          {{sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",selectedFundType)}}
          <span class="material-icons">arrow_drop_down</span>
        </button>
        <mat-menu #fundTypes="matMenu">
          <ng-container *ngFor="let fundType of fundTypeList">
            <button mat-menu-item
                    (click)='nameSearchValue.value = "";selectedFundType = fundType.ShortName; onFundTypeChange()'>{{fundType.Name}}</button>
          </ng-container>
        </mat-menu>
        <h5 class='mb-0 line-height-36px'>Funds</h5>
      </div>

      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme/Fund Name" aria-label="Search" #nameSearchValue
               (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- fund table -->
    <li class="list-group-item border-top-dashed"
        *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">

      <div class="text-right mb-3"><button mat-flat-button color="qm-blue" (click)="generateCSV()">Export to CSV</button></div>

      <div class='px-2'>
        <p class='text-right text-pumpkin'><strong>Max Return Value: {{maxReturnValue | number:'1.2-2'}}</strong></p>
      </div>
      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
               class="table table-bordered table-info">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top' *matHeaderCellDef>Scheme Name (Number)</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}} ({{item.SchemeNumber}})
            </td>
          </ng-container>

          <!-- Fund Name -->
          <ng-container matColumnDef="FundName">
            <th class='align-top' *matHeaderCellDef>Fund Name (Number)</th>
            <td class='align-middle' *matCellDef="let item">
              <button mat-button class='text-blue text-wrap text-left line-height-md' title='year return'
                      (click)='triggerYearReturnData(item);loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-value", "display year return data");'>
                {{item.FundName}} ({{item.FundNumber}})
              </button>
            </td>
          </ng-container>

          <!-- Number of the Valued Year -->
          <ng-container matColumnDef="NumberOfYear">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Number of the Valued Year</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.NumberOfYear}}
            </td>
          </ng-container>

          <!-- Average Return -->
          <ng-container matColumnDef="MaxNumberOfAverageReturn">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Average Return</th>
            <td class='align-middle text-right' [ngClass]="{'text-danger': item.MaxNumberOfAverageReturn < 0}" *matCellDef="let item">
              {{item.MaxNumberOfAverageReturn | number:'1.2-2' }}
            </td>
          </ng-container>

          <!-- Scale Factor -->
          <ng-container matColumnDef="RatingSubtractLoadingInPercentage">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Scale Factor</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.RatingSubtractLoadingInPercentage}}
            </td>
          </ng-container>

          <!-- Rating -->
          <ng-container matColumnDef="Rating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Rating</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.Rating * 100 | number:'1.0-0' }}%
            </td>
          </ng-container>

          <!-- Rating Name -->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Rating Name</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle' [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
          </ng-container>

          <!-- Fund Detail Button -->
          <ng-container matColumnDef="FundDetail">
            <th class='align-top' *matHeaderCellDef>Fund Detail</th>
            <td class='align-middle w-210px w-min-210px w-max-210px' *matCellDef="let item">
              <button mat-button title='fund detail' *ngIf="item.QmSchemeFundMorningStarMappingId > 0"
                      (click)='viewFundDetail(item);loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-value", "go to view fund detail");'>
                <div class='row'>
                  <div class='col-auto pr-0'>
                    <span class="material-icons align-middle" aria-label="open in new">open_in_new
                    </span>
                  </div>
                  <div class='col align-self-center'>
                    <span class='align-middle'>View Fund Detail</span>
                  </div>
                </div>
              </button>
            </td>
          </ng-container>

          <!-- year return -->
          <ng-container matColumnDef="YearReturn">
            <td colspan='8' *matCellDef="let item">
              <table class="table table-sm table-borderless mb-0 hover-disable">
                <tr class='border-bottom qm-bg-disable text-center qm-text-sm'>
                  <th *ngFor="let data of item.YearReturn">{{ data.Item1 }}</th>
                  <th class='text-center text-pumpkin'>Avg</th>
                </tr>
                <tbody>
                  <tr class='qm-bg-disable text-center font-weight-bolder'>
                    <td *ngFor="let data of item.YearReturn"
                        [ngClass]="{'text-muted': data.Item2 === null, 'text-danger': data.Item2 < 0}">
                      {{ data.Item2 !== null ? data.Item2 : 'N/A' }}
                    </td>
                    <td class='text-center text-pumpkin'>{{item.MaxNumberOfAverageReturn | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns; let i = dataIndex;" [ngClass]="{'qm-bg-info-striped': (i + 1) % 2 === 1}"></tr>

          <tr mat-row *matRowDef="let item; columns: ['YearReturn'];"
              [ngClass]="{'d-none': !isRowExpanded(item.QmSchemeFundId)}">
          </tr>
        </table>
        <!-- paginator -->
        <mat-paginator class='qm-paginator'></mat-paginator>
      </div>
    </li>
  </ul>
</div>