<ul class="list-group list-group-flush">
  <!-- product settings -->
  <li class='list-group-item'>
    <div class='table-responsive setting-quote-table'>
      <table class="table table-bordered table-info">
        <thead>
          <tr class='border'>
            <div class='row flex-nowrap mx-0'>
              <div class='col-2 py-2 px-2'>
                <strong>Select Provider</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Health</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Trauma</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>IP Taxable</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>IP Non Taxable</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Mortgage</strong>
              </div>
            </div>
          </tr>
        </thead>
        <tbody class='bg-white'>
          <tr class='border' *ngFor="let provider of userProviderSetting">
            <div class='row flex-nowrap mx-0'>
              <div class='col-2 py-2 px-2'>
                <mat-checkbox [(ngModel)]="provider.IsSelected" (change)="changeProviderSetting(provider)"
                  class="ml-1 provider-checkbox">
                  <img src="{{provider.ProviderLogoUrl}}?t={{timestamp}}" class='pl-3' />
                </mat-checkbox>
              </div>

              <ng-container *ngIf='provider.IsSelected'>
                <!--Health-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 1'>
                      <ng-container
                        *ngIf='benefit.ProductEntityList && benefit.ProductEntityList.length > 0'>
                        <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                          (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                      </ng-container>
                    </ng-container>
                  </ng-container>


                </div>

                <!--Trauma-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 4  '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--IP taxable-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 6   '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--IP non-taxable-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 10   '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--MP-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 7  '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf='!provider.IsSelected'>
                <!--Health-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--Trauma-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--IP taxable-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--IP non-taxable-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--MP-->
                <div class='col-2 py-2 px-2'>
                </div>
              </ng-container>
            </div>

            <div class='row flex-nowrap mx-0' *ngIf='provider.ProviderId === 1 && provider.IsSelected '>
              <div class='col-2 pt-2 px-2'>
              </div>
              <div class='col-10 pt-2 pl-4 pr-2 border-top'>
                <span class="align-text-bottom mr-4">AIA Vitality Discount
                  Type:</span>
                <mat-checkbox class="mr-4" (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="provider.AIAVitalityFeeDiscount">
                  AIA Vitality Fee
                </mat-checkbox>
                <mat-checkbox (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="provider.AIAVitalityDiscount">
                  AIA Vitality Discount
                </mat-checkbox>
              </div>
            </div>

            <div class='row flex-nowrap mx-0' *ngIf='provider.ProviderId === 10 && provider.IsSelected '>
              <div class='col-2 pt-2 px-2'>
              </div>
              <div class='col-10 pt-2 pl-4 pr-2 border-top'>
                <mat-checkbox class="mr-4" (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="isApplySouthernCrossReward">Apply Healthy Lifestyle Reward?
                </mat-checkbox>
              </div>
            </div>

            <div class='row flex-nowrap mx-0' *ngIf='provider.ProviderId === 9 && provider.IsSelected '>
              <div class='col-2 pt-2 px-2'>
              </div>
              <div class='col-10 pt-2 pl-4 pr-2 border-top'>
                <mat-checkbox class="mr-4" (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="isApplyChubbReward">Apply Lifetime Reward?
                </mat-checkbox>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- next, reset to default buttons -->
    <div class='row justify-content-between'>
      <div class='col-auto'>
        <button mat-flat-button color="warn" (click)='showResetAlert = !showResetAlert'
                title='click here to reset your product settings to default'>Reset to default</button>
      </div>
      <div class='col-auto'>
        <button mat-flat-button color="qm-pumpkin" (click)='changeSelectedIndex.emit()'
                title='next'>Next</button>
      </div>
    </div>
    <!-- reset to default alert -->
    <div class='alert alert-warning mt-2' *ngIf='showResetAlert'>
      <div class='text-center'>
        <strong>{{sharedFunction.getUiMessageByCode("Share-WARNING-ResetToDefault")}}</strong>
        <button mat-raised-button class='mx-3' color="warn"
          (click)='showResetAlert=false;resetProviderSetting();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>YES</button>

        <button mat-raised-button color="qm-black"
          (click)='showResetAlert=false;loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>NO</button>
      </div>
    </div>
  </li>
</ul>