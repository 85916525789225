<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Scheme Size Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed">
      <!-- name search -->
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme Name" aria-label="Search"
          (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>
    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3"><button mat-flat-button color="qm-blue" (click)="generateCSV()">Export to
          CSV</button>
      </div>

      <div class="row">
        <div class="col align-self-end">
          <p class='mb-0 text-pumpkin'><strong>Total Members: {{totalMembers | number:'1.0-0'}}</strong></p>
        </div>
        <div class="col-auto">
          <p class='mb-0 text-pumpkin'><strong>Min Rating: {{minRating * 100 | number:'1.0-0'}}%</strong></p>
          <p class='mb-0 text-pumpkin'><strong>Max Rating: {{maxRating * 100 | number:'1.0-0'}}%</strong></p>
        </div>
      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top w-400px w-min-400px w-max-400px' *matHeaderCellDef mat-sort-header>Scheme Name</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}}
            </td>
          </ng-container>
          <!-- Members -->
          <ng-container matColumnDef="Members">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Members</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.Members | number:'1.0-0'}}
            </td>
          </ng-container>
          <!-- FUM -->
          <ng-container matColumnDef="FUM">
            <th class='align-top' *matHeaderCellDef mat-sort-header>FUM</th>
            <td class='align-middle text-right' *matCellDef="let item">
              ${{item.FUM | number:'1.0-0'}}
            </td>
          </ng-container>
          <!-- AdjustedMembers -->
          <ng-container matColumnDef="AdjustedMembers">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Adjusted Members</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.AdjustedMembers | number:'1.0-0'}}
            </td>
          </ng-container>
          <!-- AdjustedFUM -->
          <ng-container matColumnDef="AdjustedFUM">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Adjusted FUM</th>
            <td class='align-middle text-right' *matCellDef="let item">
              ${{item.AdjustedFUM | number:'1.0-0'}}
            </td>
          </ng-container>
          <!-- ScaleCalc -->
          <ng-container matColumnDef="ScaleCalc">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Scale Calc</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.ScaleCalc | number:'1.0-0'}}
            </td>
          </ng-container>
          <!-- Rating -->
          <ng-container matColumnDef="Rating">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Rating</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.Rating * 100 | number:'1.0-0' }}%
            </td>
          </ng-container>
          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Reported Scale</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle' [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
        </table>
        <!-- paginator -->
        <!-- <mat-paginator class='qm-paginator'></mat-paginator> -->
      </div>
    </li>
  </ul>
</div>