<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">{{data.Title}}</h4>
</div>

<div mat-dialog-content>
    <div [innerHtml]="data.Message ? data.Message : 'Something wrong, please try again.' | safeHtml"></div>
</div>

<div mat-dialog-actions align="end">
    <button *ngIf='data.NeedNoBtn' mat-flat-button color="qm-black"
        (click)="close(data.NoBtnValue)">{{data.NoBtnName}}
    </button>
    <button *ngIf='data.NeedYesBtn' mat-flat-button color="qm-blue" class="ml-3"
        (click)="close(data.YesBtnValue)">{{data.YesBtnName}}
    </button>
</div>