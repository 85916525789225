<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Open Version: {{data.CurrentVersion.DocName}}</h4>
</div>
<div mat-dialog-content class='legacy-compare-dialog-content'>
    <ul class='list-group'>
        <li class='list-group-item'>
            <!-- company/productType/productLine/product information -->
            <div class='row'>
                <div class='col'>
                    <h6 class='mb-0 text-muted'>Company</h6>
                    <p>{{data.Company.Name}}</p>
                </div>
                <div class='col'>
                    <h6 class='mb-0 text-muted'>Product Type</h6>
                    <p>{{data.ProductType.ProductTypeName}}</p>
                </div>
                <div class='col'>
                    <h6 class='mb-0 text-muted'>Product Line</h6>
                    <p>{{data.ProductLine.ProdLineDesc}}</p>
                </div>
                <div class='col'>
                    <h6 class='mb-0 text-muted'>Product</h6>
                    <p>{{data.Product.ProductName}} {{data.Product.ProductExtName}}</p>
                </div>
            </div>
        </li>
        <!-- compare version -->
        <li class='list-group-item border-top-dashed'>
            <div class='row align-items-center'>
                <div class='col-12'>
                    <p class='text-blue'>Compare With</p>
                </div>
                <!-- version options -->
                <div class='col-12'>
                    <app-select-search placeholderLabel='Version'
                                       floatLabelValue='never' appearanceValue='outline'
                                       [selectedData]='compareWithVersion'
                                       (onSelectedDataChange)="compareWithVersion=$event;"
                                       [selectOptions]='versionOptions'
                                       displayValue='DocName'
                                       *ngIf="versionOptions.length > 0">
                    </app-select-search>
                </div>
            </div>
        </li>
    </ul>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)='close()'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)='goNext()'>Next</button>
</div>