<ul class='list-group list-group-flush'>

  <li class='list-group-item'>
    <!-- Quote Name search -->
    <div class="input-group mb-4">
      <div class="input-group-prepend">
        <span class="input-group-text"><span class="material-icons">search</span></span>
      </div>
      <input type="text" class="form-control" placeholder="Search" (keyup)="doSearch($event.target.value)"
             appAutoSelect>
    </div>

    <!-- saved bulk quotes table -->
    <div class='table-responsive'>
      <table class='table table-bordered table-info table-hover'>
        <!-- table header -->
        <tr>
          <th class='w-160px w-min-160px w-max-160px'>Need to Run?</th>
          <th>Quote Name</th>
          <th class='w-280px w-min-280px w-max-280px'>Create Date</th>
        </tr>

        <!-- table body -->
        <tbody class='bg-white'>
          <ng-container *ngIf="displayBulkQuotes.length > 0">
              <tr *ngFor="let bulkQuote of displayBulkQuotes">
                <td class='text-center'><mat-checkbox [(ngModel)]="bulkQuote.Selected"
                                (change)='getSelectedBulkQuotes()'></mat-checkbox></td>
                <td>
                  <button mat-button class='text-blue' title='detail'
                          (click)='getBulkQuoteDetail(bulkQuote);loginService.doGoogleTracking("InternalAdminPortal", "bulk-quote", "get saved bulk quote details");'>
                    {{bulkQuote.StringValue2}}
                  </button>
                </td>
                <td>{{bulkQuote.StringValue3}}</td>
              </tr>
          </ng-container>
          <ng-container *ngIf="errorMessage">
            <tr>
              <td colspan="3">
                <div class='alert alert-danger text-center' role='alert'>
                  {{errorMessage}}
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class='row justify-content-between'>
      <div class='col'>
        <button mat-flat-button color="warn"
                (click)='openConfirmCancelDialog();loginService.doGoogleTracking("InternalAdminPortal","bulk-quote", "delete quotes");'
                [disabled]='selectedBulkQuotesCount === 0'>
          {{ selectedBulkQuotesCount > 0 ? 'Delete (' + selectedBulkQuotesCount + ')' : 'Delete' }}
        </button>
      </div>
      <div class='col-auto'>
        <button mat-flat-button color="qm-pumpkin" class='mr-4'
                (click)='runSavedBulkQuotes();loginService.doGoogleTracking("InternalAdminPortal","bulk-quote", "run saved bulk quotes");'
                [disabled]='selectedBulkQuotesCount === 0'>
          {{ selectedBulkQuotesCount > 1
          ? 'Run (' + selectedBulkQuotesCount + ') Bulk Quotes'
          : selectedBulkQuotesCount === 0
          ? 'Run Bulk Quote'
          : 'Run (1) Bulk Quote' }}
        </button>
        <button mat-flat-button color="qm-blue"
                (click)='startNewBulkQuote();loginService.doGoogleTracking("InternalAdminPortal","bulk-quote", "start a new bulk quote");'>
          Start a New Bulk Quote
        </button>
      </div>
    </div>
  </li>
</ul>