import { QuoteService } from '../quote.service';
import { Provider } from '../../models/provider';
import { Benefit } from '../../models/benefit';
import { SelectItemStruct } from '../../models/select.item';
import { QuoteResult } from '../../models/quote.result';
import { QuoteResultItem } from '../../models/quote.result.item';
import { DevTools } from '../dev.tools';
import { formatCurrency } from '@angular/common';
import { UserQmSettingModel } from 'src/app/models/user.qm.setting';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { BenefitGroup } from 'src/app/models/benefit.group';
import { QueryBulkQuote } from 'src/app/models/bulk-quote-data.model';
import { SpecialQuoteSetting } from 'src/app/models/special.quote.setting';
import { Observable } from 'rxjs';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { QuoteOptionSpecificInjury } from 'src/app/models/quote.option.si';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepBenefit extends QuoteService {
    hasLife: boolean = false;
    lifeCoverAmount: number = 0;
    hasLevelLife: boolean = false;
    levelLifeCoverAmount: number = 0;
    selectedUserProviderSetting: Provider[];
    lastBenefitId: number;
    userQmSettingModel: UserQmSettingModel = new UserQmSettingModel();
    showReCrunchAlert: boolean = false;

    frequencyLoading: number = 12;
    factorLoading = 0;

    benefitGroup: BenefitGroup[] = [];
    benefitSelection: Benefit[] = [];
    bulkQuoteResultList: QuoteResult[] = [];

    correctQuoteResultItems: QuoteResultItem[] = [];
    errorQuoteResultItems: QuoteResultItem[] = [];
    hasChild: boolean;
    isLoading: boolean = false;

    init() {
        new DevTools().log(`QuoteStepBenefit.init`, null);

        this.errorMessage = [];
        this.setFamilyCoverBenefitPeriodAgeData();

        this.bulkQuoteResultList = [];
        this.benefitGroup = this.getBenefitGroup();
        this.loadDefaultBenefitSettings();
        this.benefitSelection = [];
        // init query bulk quote data, do select benefit for every saved benefits
        this.selectSavedBenefits();
        if (this.savedQueryBulkQuote.SavedBenefits.length === 0) {
            // new quote
            this.showDinoLoading();
            // get frequencyLoading
            this.getUserQmSetting((response) => {
                if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                    this.frequencyLoading = Number(response.QmSetting.DEF_FREQ);
                }

                if (this.savedQueryBulkQuote.UserProviderSettings.length === 0) {
                    // if no provider, load provider
                    this.getUserProviderSetting((response) => {
                        this.savedQueryBulkQuote.UserProviderSettings = response;
                        this.setSelectedUserProviderSetting();
                        this.closeDinoLoading();
                    });
                } else {
                    this.setSelectedUserProviderSetting();
                    this.closeDinoLoading();
                }
            });
        } else {
            // saved quote
            this.setSelectedUserProviderSetting();
            this.frequencyLoading = this.savedQueryBulkQuote.SavedBenefits[0].QuoteOption.Frequency;
        }
    }

    setSelectedUserProviderSetting(): void {
        this.selectedUserProviderSetting = this.savedQueryBulkQuote.UserProviderSettings.filter(p => p.IsSelected);
        if (this.selectedUserProviderSetting.length === 0) {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode('QuoteStepBenefit-ERROR-NoSelectedProvider'));
        }
    }

    setFamilyCoverBenefitPeriodAgeData(): void {
        this.familyCoverBenefitPeriodAgeListTerm = [];
        for (let i = 2; i < 31; i++) {
            this.familyCoverBenefitPeriodAgeListTerm.push(i);
        }

        this.familyCoverBenefitPeriodAgeListToAge = [];
        for (let i = 31; i < 71; i++) {
            this.familyCoverBenefitPeriodAgeListToAge.push(i);
        }
    }

    loadDefaultBenefitSettings() {
        // set default data
        for (let benefitGroup of this.benefitGroup) {
            for (let benefit of benefitGroup.BenefitList) {
                benefit.QuoteOption.Loading = this.loadingList[0];
                if (benefit.UIBenefitId === 1) {
                    benefit.QuoteOption.Excess = this.healthExcessList[0];
                } else if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7 || benefit.UIBenefitId === 9) {
                    benefit.QuoteOption.WeekWaitPeriod = this.waitPeriod[0];
                    benefit.QuoteOption.BenefitPeriod = this.benefitPeriod[3];

                    if (benefit.UIBenefitId === 6) {
                        benefit.QuoteOption.IpMonthlyCoverAmount = benefit.QuoteOption.CoverAmount / 12 || 0;
                    }
                } else if (benefit.UIBenefitId === 11 || benefit.UIBenefitId === 12 || benefit.UIBenefitId === 13) {
                    benefit.QuoteOption.CalcPeriod = this.calcPeriodList[0];
                    benefit.QuoteOption.IsLevel = true;
                    benefit.QuoteOption.Indexed = false;
                } else if (benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15) {
                    // default trauma accelerated and TPD accelerated are disabled
                    benefit.IsDisabled = true;
                }
            }
        }
    }

    selectSavedBenefits() {
        let savedBenefits = this.savedQueryBulkQuote.SavedBenefits;

        if (savedBenefits.length > 0) {

            this.resetSavedBenefitProperty(savedBenefits);

            this.benefitGroup.forEach(bg => bg.BenefitList.forEach(b => {
                let savedB = savedBenefits.find(sb => sb.UIBenefitId === b.UIBenefitId);
                if (savedB) {
                    this.removeBenefit(b);
                    Object.assign(b, savedB);
                    this.selectBenefit(savedB);
                } else {
                    this.removeBenefit(b);
                }
            }));
        }
    }

    resetSavedBenefitProperty(savedBenefits: Benefit[]): void {
        let lifeCoverIsSelected: boolean = false;
        for (let benefit of savedBenefits) {
            // set benefit ui id
            benefit.UIBenefitId = benefit.BenefitId;
            // set level benefit
            if (benefit.QuoteOption && benefit.QuoteOption.IsLevel === true) {
                if (benefit.BenefitId === 2) {
                    benefit.UIBenefitId = 11; // move life to level life
                } else if (benefit.BenefitId === 4) {
                    benefit.UIBenefitId = 12; // move trauma to level trauma
                } else if (benefit.BenefitId === 5) {
                    benefit.UIBenefitId = 13; // movel tpd to level tpd
                }
            }

            // health
            if (benefit.UIBenefitId === 1) {
                benefit.QuoteOption.IncludeChildren = false;
            }
            // trauma
            if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
                benefit.QuoteOption.ChildTrauma = false;
                benefit.QuoteOption.ChildCoverAmount = 0;
            }

            // set accelerated trauma and TPD
            if (benefit.BenefitId === 4 && benefit.QuoteOption.Standalone !== true && benefit.UIBenefitId !== 12) {
                benefit.UIBenefitId = 14; // set accelerated trauma
            } else if (benefit.BenefitId === 5 && benefit.QuoteOption.Standalone !== true && benefit.UIBenefitId !== 13) {
                benefit.UIBenefitId = 15; // set accelerated TPD
            }

            // set ip monthly cover amount
            if (benefit.BenefitId === 6 || benefit.BenefitId === 10) {
                benefit.QuoteOption.IpMonthlyCoverAmount = benefit.QuoteOption.CoverAmount / 12 || 0;
                // set benefit to IP = 6
                if (benefit.BenefitId === 10) {
                    benefit.BenefitId = 6;
                    benefit.UIBenefitId = 6;
                }
            }

            // if life cover is selected, trauma accelerated and TPD accelerated are enabled
            if (benefit.BenefitId === 2) {
                lifeCoverIsSelected = true;
                for (let benefitGroup of this.benefitGroup) {
                    for (let b of benefitGroup.BenefitList) {
                        if (b.UIBenefitId === 14 || b.UIBenefitId === 15) {
                            b.IsDisabled = false;
                        }
                    }
                }
            }

            // if trauma accelerated or TPD accelerated is selected,  life cover is selected (eg. enter ta or tpd cover amount on need analysis cal, but not enter life cover amount)
            if (benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15) {
                if (!lifeCoverIsSelected) {
                    for (let benefitGroup of this.benefitGroup) {
                        for (let b of benefitGroup.BenefitList) {
                            if (b.UIBenefitId === 2) {
                                lifeCoverIsSelected = true;
                                b.IsSelected = true;
                                this.selectBenefit(b);
                            }
                        }
                    }
                }
            }

            // set specific injury benefit ExtData (boolean) from ExtDataInJson
            if (benefit.UIBenefitId === 23) {
                (benefit.QuoteOption as QuoteOptionSpecificInjury).ExtData = JSON.parse((benefit.QuoteOption as QuoteOptionSpecificInjury).ExtDataInJson).INCPL === "true";
            }
            // set benefit selector
            for (let benefitGroup of this.benefitGroup) {
                for (let b of benefitGroup.BenefitList) {
                    if (benefit.UIBenefitId === b.UIBenefitId) {
                        b.IsSelected = true;
                        break;
                    }
                }
            }
        }
    }

    loadUserQmSetting() {

    }

    selectBenefit(benefit: Benefit) {
        if (benefit.IsSelected === true) {
            // add benefit to client
            this.benefitSelection.push(benefit);

            // if benefit is life cover then enable trauma accelerated and tpd accelerated
            if (benefit.BenefitId === 2) {
                for (let g of this.benefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.UIBenefitId === 14 || b.UIBenefitId === 15) {
                            b.IsDisabled = false;
                        }
                    }
                }
            }

            // if benefit is MP OR IP then auto wop
            if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7) {
                // check wop has been selected or not
                for (let g of this.benefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.UIBenefitId === 9 && b.IsSelected !== true) {
                            b.IsSelected = true;
                            this.benefitSelection.push(b);
                            break;
                        }
                    }
                }
            } else if (benefit.UIBenefitId === 12 || benefit.UIBenefitId === 13) {
                if (this.checkLifeCover()) {
                    benefit.QuoteOption.Accelerated = true;
                    benefit.QuoteOption.Standalone = false;
                }
            } else if (benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15) {
                // set trauma accelerated and tpd accelerated.
                benefit.QuoteOption.Accelerated = true;
                benefit.QuoteOption.Standalone = false;
            }

            this.lastBenefitId = benefit.UIBenefitId;

            this.loginService.doGoogleTracking('bulk-quote-benefit', 'addBenefit', this.getBenefitName(benefit));
        } else {
            // remove benefit from client
            this.removeBenefit(benefit);
            this.loginService.doGoogleTracking('bulk-quote-benefit', 'removeBenefit', this.getBenefitName(benefit));
        }

        // sort benfit list
        this.benefitSelection = this.sortBenefitList(this.benefitSelection);

    }

    removeBenefit(benefit: Benefit) {
        let bList = [];

        let lifeCoverIsRemoved: boolean = false;
        // remove benefit from benefit list
        for (let b of this.benefitSelection) {
            if (b.UIBenefitId !== benefit.UIBenefitId) {
                bList.push(b);
            }
        }

        // remove trauma Accelerated or TPD Accelerated if remove life cover
        if (benefit.UIBenefitId === 2) {
            lifeCoverIsRemoved = true;
            bList = bList.filter((item) => !(item.UIBenefitId === 14 || item.UIBenefitId === 15));
        }

        // mark benefit unchecked in client.BenefitGroup.Benefit
        for (let bg of this.benefitGroup) {
            for (let b of bg.BenefitList) {
                if (!bList.find((item) => item.UIBenefitId === b.UIBenefitId)) {
                    b.IsSelected = false;
                }
                if (lifeCoverIsRemoved) {
                    if (b.UIBenefitId === 14 || b.UIBenefitId === 15) {
                        b.IsDisabled = true;
                    }
                }
            }
        }

        this.benefitSelection = bList;
    }

    checkLifeCover(): boolean {
        this.hasLife = false;
        this.lifeCoverAmount = 0;
        this.hasLevelLife = false;
        this.levelLifeCoverAmount = 0;
        if (this.benefitSelection.length > 0) {
            for (let benefit of this.benefitSelection) {
                if (benefit.UIBenefitId === 2) {
                    this.hasLife = true;
                    this.lifeCoverAmount = benefit.QuoteOption.CoverAmount;
                } else if (benefit.UIBenefitId === 11) {
                    this.hasLevelLife = true;
                    this.levelLifeCoverAmount = benefit.QuoteOption.CoverAmount;
                }
            }
        }

        return this.hasLife || this.hasLevelLife;
    }

    setDefaultInsurerValue(value1: SelectItemStruct, value2: SelectItemStruct): boolean {
        if (value1 && value2) {
            return value1.Value === value2.Value;
        } else {
            return false;
        }
    }





    addFrequencyAndFactor() {
        // for bulk quoting, always set factorLoading to 100% (value is 0)
        this.factorLoading = 0;
        for (let b of this.benefitSelection) {
            b.QuoteOption.Frequency = this.frequencyLoading;
            b.QuoteOption.Factor = this.factorLoading;
        }
    }

    addBenefits() {
        for (let quoteData of this.savedQueryBulkQuote.QuoteDatas) {
            for (let client of quoteData.PeopleEntity) {
                if (!client.IsChild) {
                    client.BenefitList = JSON.parse(JSON.stringify(this.benefitSelection));
                    if (client.ClientId !== 0) {
                        for (let b of client.BenefitList) {
                            if (b.UIBenefitId === 12) {
                                b.QuoteOption.ChildTrauma = false;
                                b.QuoteOption.ChildCoverAmount = 0;
                                break;
                            }
                        }
                    }
                }
                if (client.IsChild) {
                    client.BenefitList = [];
                    if (this.benefitSelection.filter((b) => b.UIBenefitId === 1).length > 0) {
                        client.BenefitList.push(this.benefitSelection.filter((b) => b.UIBenefitId === 1)[0]);
                    }
                    if (this.benefitSelection.filter((b) => b.UIBenefitId === 4).length > 0) {
                        let childTraumaStandalone = JSON.parse(
                            JSON.stringify(this.benefitSelection.filter((b) => b.UIBenefitId === 4)[0])
                        );
                        childTraumaStandalone.QuoteOption.CoverAmount = 50000;
                        client.BenefitList.push(childTraumaStandalone);
                    }
                }
            }
        }
    }

    addSpecialProviderSetting() {
        let specialQuoteSettings: SpecialQuoteSetting[] = [];
        for (let p of this.selectedUserProviderSetting) {
            if (p.SpecialProviderDiscountCode) {
                let specialQuoteSetting = new SpecialQuoteSetting();
                specialQuoteSetting.ProviderId = p.ProviderId;
                specialQuoteSetting.SpecialProviderSetting = p.SpecialProviderDiscountCode;
                specialQuoteSettings.push(specialQuoteSetting);
            }
        }

        for (let quoteData of this.savedQueryBulkQuote.QuoteDatas) {
            quoteData.SpecialQuoteSettings = specialQuoteSettings;
        }
    }

    validation(): boolean {
        let pass = true;
        this.errorMessage = [];
        let totalBenefit: number = 0;
        let numberOfOwnOcc: number = 0;
        let numberOfAnyOcc: number = 0;

        // if (this.quoteDataList.length <= 0) {
        //     this.errorMessage.push('Please enter client details.');
        //     pass = false;
        // }

        for (let benefit of this.benefitSelection) {
            // check cover amount
            if (benefit.UIBenefitId !== 1 && benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 6) {
                if (!benefit.QuoteOption.CoverAmount || benefit.QuoteOption.CoverAmount <= 0) {
                    this.errorMessage.push(`Please enter cover amount for ${ benefit.BenefitName }`);
                    pass = false;
                }
            }

            // check ip monthly cover amount
            if (benefit.UIBenefitId === 6) {
                if (!benefit.QuoteOption.IpMonthlyCoverAmount || benefit.QuoteOption.IpMonthlyCoverAmount <= 0) {
                    this.errorMessage.push(`Please enter cover amount for ${ benefit.BenefitName }`);
                    pass = false;
                }
            }

            // check special benefit
            if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
                if (benefit.UIBenefitId === 14 && !this.checkLifeCover()) {
                    this.errorMessage.push(`Please add life cover for ${ benefit.BenefitName }`);
                    pass = false;
                }

                //  shouldn't be able to quote Any Occ and Own Occ together on Trauma TPD add On
                if (benefit.QuoteOption.TpdAddOn === true) {
                    if (benefit.QuoteOption.TpdOwnOccupation === true) {
                        numberOfOwnOcc++;
                    } else {
                        numberOfAnyOcc++;
                    }
                }
            } else if (benefit.UIBenefitId === 12) {
            } else if (benefit.UIBenefitId === 15) {
                if (!this.checkLifeCover()) {
                    this.errorMessage.push(`Please add life cover for ${ benefit.BenefitName }`);
                    pass = false;
                }
            }

            //  shouldn't be able to quote Any Occ and Own Occ together on TPD
            if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
                if (benefit.QuoteOption.OwnOccupation === true) {
                    numberOfOwnOcc++;
                } else {
                    numberOfAnyOcc++;
                }
            }

            totalBenefit++;

            // set some special benefit setting
            if (benefit.UIBenefitId === 1) {
                // health
            } else if (benefit.UIBenefitId === 2) {
                // life
                benefit.QuoteOption.CalcPeriod = new SelectItemStruct(
                    'Yearly Renewable',
                    '',
                    1,
                    false,
                    0,
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                );
            } else if (benefit.UIBenefitId === 11) {
                // level life
                benefit.QuoteOption.Indexed = false;
                benefit.QuoteOption.IsLevel = true;
                benefit.BenefitId = 2;
            } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
                // trauma
                // benefit.QuoteOption.Indexed = true;
                benefit.QuoteOption.IsLevel = false;

                if (benefit.UIBenefitId === 4) {
                    // benefit.QuoteOption.Standalone = true;
                } else if (benefit.UIBenefitId === 14) {
                    // benefit.QuoteOption.Standalone = false;
                    benefit.BenefitId = 4;
                }

                if (this.hasLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                }

                benefit.QuoteOption.TpdAnyOccupation = !benefit.QuoteOption.TpdOwnOccupation;
                benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;

                if (benefit.QuoteOption.Standalone) {
                    benefit.QuoteOption.LifeBuyBack = false;
                }
            } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
                // TPD
                // benefit.QuoteOption.Indexed = true;
                benefit.QuoteOption.IsLevel = false;

                if (benefit.UIBenefitId === 5) {
                    // benefit.QuoteOption.Standalone = true;
                } else if (benefit.UIBenefitId === 15) {
                    // benefit.QuoteOption.Standalone = false;
                    benefit.BenefitId = 5;
                }

                if (this.hasLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                }
                benefit.QuoteOption.AnyOccupation = !benefit.QuoteOption.OwnOccupation;
                benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;
            } else if (benefit.UIBenefitId === 6) {
                // IP
                benefit.QuoteOption.IsLevel = false;
                if (benefit.QuoteOption.IsTaxable === 2) {
                    benefit.BenefitId = 6;
                } else {
                    benefit.BenefitId = 10;
                }

                benefit.QuoteOption.CoverAmount = benefit.QuoteOption.IpMonthlyCoverAmount * 12;
            } else if (benefit.UIBenefitId === 12) {
                // level trauma
                benefit.BenefitId = 4;
                benefit.QuoteOption.Indexed = false;
                benefit.QuoteOption.IsLevel = true;

                // what i am do ting here.

                if (!this.checkLifeCover()) {
                    benefit.QuoteOption.Standalone = true;
                }

                benefit.QuoteOption.TpdAnyOccupation = !benefit.QuoteOption.TpdOwnOccupation;

                benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;

                if (this.hasLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                }

                if (benefit.QuoteOption.Standalone) {
                    benefit.QuoteOption.LifeBuyBack = false;
                }
            } else if (benefit.UIBenefitId === 13) {
                // level TPD
                benefit.BenefitId = 5;
                benefit.QuoteOption.Indexed = false;
                benefit.QuoteOption.IsLevel = true;

                if (!this.checkLifeCover()) {
                    benefit.QuoteOption.Standalone = true;
                }

                if (this.hasLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                    benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                }
                benefit.QuoteOption.AnyOccupation = !benefit.QuoteOption.OwnOccupation;
                benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;
            }

            // set some special benefit setting end
        }

        if (numberOfAnyOcc > 0 && numberOfOwnOcc > 0) {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode('QuoteStepBenefit-ERROR-TPD'));
            pass = false;
        }

        if (totalBenefit <= 0) {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode('QuoteStepBenefit-ERROR-TotalBenefits'));
            pass = false;
        }

        return pass;
    }

    doCrunchObservable(chunkSize: number): Observable<any> {
        let count = 0;
        return new Observable((observer) => {
            for (let i = 0; i < this.savedQueryBulkQuote.QuoteDatas.length; i += chunkSize) {
                let chunkQuoteDataList = this.savedQueryBulkQuote.QuoteDatas.slice(i, i + chunkSize);
                this.doBulkCrunch(chunkQuoteDataList, (response) => {
                    if (response) {
                        observer.next(response);
                        count += chunkSize;
                        if (count >= this.savedQueryBulkQuote.QuoteDatas.length) {
                            observer.complete();
                        }
                    }
                });
            }
        });
    }

    callBulkCrunch() {
        if (this.validation()) {
            this.showDinoLoading();
            this.isLoading = true;
            
            this.savedQueryBulkQuote.SavedBenefits = this.benefitSelection;
            
            this.addFrequencyAndFactor();
            this.addBenefits();
            this.addSpecialProviderSetting();
            this.bulkQuoteResultList = [];

            const chunkSize = 20;
            this.doCrunchObservable(chunkSize).subscribe({
                next: (value: QuoteResult[]) => {
                    this.bulkQuoteResultList = this.bulkQuoteResultList.concat(value);
                },
                complete: () => {
                    // reload result
                    this.quoteResultFilter(this.bulkQuoteResultList);
                    this.bulkQuoteResultList.sort((a, b) => a.AppId - b.AppId);
                    for (let result of this.bulkQuoteResultList) {
                        this.setQuoteResultMessages(result.QuoteResultList);
                    }
                    // update quote
                    let quoteDate = dayjs().format('DD MMM YYYY, HH:mm');
                    this.closeDinoLoading();
                    this.isLoading = false;
                },
            });
        } else {
            this.closeDinoLoading();
        }
    }

    doSendBulkQuoteResult(doneSendEmail: () => void) {
        this.errorMessage = [];

        if (this.validation()) {
            this.addFrequencyAndFactor();
            this.addBenefits();
            this.addSpecialProviderSetting();

            let queryBulkQuote = new QueryBulkQuote();
            queryBulkQuote.QuoteName = this.savedQueryBulkQuote.QuoteDatas[0].QuoteName;
            queryBulkQuote.QuoteDatas = this.savedQueryBulkQuote.QuoteDatas;
            queryBulkQuote.UserProviderSettings = this.selectedUserProviderSetting;
            // call send email API
            this.sendBulkQuoteResult(queryBulkQuote, (response: any) => {
                if (response && response.MessageCode === 200) {
                    // display message dialog
                    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                    messageSetting.Title = '';
                    messageSetting.Message = response.Message;
                    messageSetting.NeedYesBtn = false;
                    messageSetting.NoBtnName = 'OK';
                    this.confirmDialog.confirm(messageSetting).subscribe(() => {
                        // back to step 1
                        doneSendEmail();
                    });
                } else {
                    this.errorMessage.push(this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed'));
                }
            });
        }
        else {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed'));
        }

    }

    setQuoteResultMessages(quoteResultList: QuoteResultItem[]): void {
        if (quoteResultList && quoteResultList.length > 0) {
            for (let result of quoteResultList) {
                // load error message
                let errorMessageCounter: number = 0;
                for (let p of result.Result) {
                    if (p.BenefitList != null && p.BenefitList.length > 0) {
                        for (let b of p.BenefitList) {
                            if (b.ErrorMessage != null && b.ErrorMessage.length > 0) {
                                for (let e of b.ErrorMessage) {
                                    errorMessageCounter++;
                                }
                            }
                        }
                    }
                }

                if (result.Provider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.Provider.ErrorMessage.length;
                }

                if (result.LinkedProvider && result.LinkedProvider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.LinkedProvider.ErrorMessage.length;
                }

                // for new APIv3
                if (result.ErrorMessages) {
                    errorMessageCounter = errorMessageCounter + result.ErrorMessages.length;
                }

                // set display total of premium with commion
                result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;

                if (result.Provider.Commission && result.Provider.Commission > 0) {
                    result.Provider.DisplayTotalOfPremium -= result.Provider.Commission;
                }

                // if has error message then set total premium to 9999999999 make sure it's in bottom.
                if (errorMessageCounter > 0) {
                    result.Provider.TotalPremium = 9999999999;
                    result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;
                }

                // set linked provider logo
                if (result.LinkedProvider) {
                    result.Provider.LinkedProviderLogoUrl = result.LinkedProvider.ProviderLogoUrl;
                } else {
                    result.Provider.LinkedProviderLogoUrl = null;
                }


            }
        }
    }


    sortQuoteResult(provider1: Provider, provider2: Provider) {
        let comparison = 0;

        if (provider1.TotalPremium > provider2.TotalPremium) {
            comparison = 1;
        } else if (provider2.TotalPremium > provider1.TotalPremium) {
            comparison = -1;
        }

        return comparison;
    }

    sortBenefitList(benefits: Benefit[]): Benefit[] {
        let sortedBenefitList: Benefit[] = [];
        let healthCovers: Benefit[] = [];
        let lifeCovers: Benefit[] = [];
        let familyCover: Benefit[] = [];
        let traumaCovers: Benefit[] = [];
        let tpdCovers: Benefit[] = [];
        let siCovers: Benefit[] = [];
        let ipCovers: Benefit[] = [];
        let mpCovers: Benefit[] = [];
        let redundancyCovers: Benefit[] = [];
        let wopCovers: Benefit[] = [];
        let otherCovers: Benefit[] = [];

        if (benefits !== null && benefits.length > 0) {
            for (let b of benefits) {
                if (b.BenefitId === 1) {
                    healthCovers.push(b);
                } else if (b.BenefitId === 2 || b.BenefitId === 11) {
                    lifeCovers.push(b);
                } else if (b.BenefitId === 3) {
                    familyCover.push(b);
                } else if (b.BenefitId === 4 || b.BenefitId === 14 || b.BenefitId === 12) {
                    traumaCovers.push(b);
                } else if (b.BenefitId === 5 || b.BenefitId === 15 || b.BenefitId === 13) {
                    tpdCovers.push(b);
                } else if (b.BenefitId === 23) {
                    siCovers.push(b);
                } else if (b.BenefitId === 6 || b.BenefitId === 10) {
                    ipCovers.push(b);
                } else if (b.BenefitId === 7) {
                    mpCovers.push(b);
                } else if (b.BenefitId === 8) {
                    redundancyCovers.push(b);
                } else if (b.BenefitId === 9) {
                    wopCovers.push(b);
                } else {
                    otherCovers.push(b);
                }
            }
        }

        sortedBenefitList = sortedBenefitList.concat(
            healthCovers,
            lifeCovers,
            familyCover,
            traumaCovers,
            tpdCovers,
            siCovers,
            ipCovers,
            mpCovers,
            redundancyCovers,
            wopCovers,
            otherCovers
        );

        return sortedBenefitList;
    }

    onIpBenefitWeekWaitPeriodChange(benefit: Benefit) {
        // check has wop or not
        for (let b of this.benefitSelection) {
            if (b.UIBenefitId === 9) {
                b.QuoteOption.WeekWaitPeriod = benefit.QuoteOption.WeekWaitPeriod;

                break;
            }
        }
    }

    getBenefitDetailHeader(benefit: Benefit): string {
        let header: string = '';

        // set cover amount
        if (!benefit.QuoteOption.CoverAmount) {
            benefit.QuoteOption.CoverAmount = 0;
        }

        header = formatCurrency(benefit.QuoteOption.CoverAmount, 'en-nz', '$', 'NZD', '1.0-0');

        if (benefit.UIBenefitId === 1) {
            // Health Cover
            if (!benefit.QuoteOption.Excess) {
                benefit.QuoteOption.Excess = this.getHealthExcess()[0];
            }
            header = benefit.QuoteOption.Excess.Name;
            header += benefit.QuoteOption.SpecialistsTest ? ', S&T' : '';
            header += benefit.QuoteOption.GpPrescriptions ? ', GP' : '';
            header += benefit.QuoteOption.DentalOptical ? ', D&O' : '';
        } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
            // Trauma
            let tCa: number = benefit.QuoteOption.CoverAmount;
            if (benefit.QuoteOption.IsSeriousTrauma === true && !isNaN(benefit.QuoteOption.SeriousTraumaCoverAmount)) {
                tCa = tCa + benefit.QuoteOption.SeriousTraumaCoverAmount;
            }

            header = formatCurrency(tCa, 'en-nz', '$', 'NZD', '1.0-0');

            header += benefit.QuoteOption.LifeBuyBack ? ', LBB' : '';
            header += benefit.QuoteOption.TraumaBuyBack ? ', TBB' : '';
            header += benefit.QuoteOption.InstantTraumaBuyback ? ', IBB' : '';
            header += benefit.QuoteOption.TpdAddOn ? ', TPD Add-On' : '';
            header += benefit.QuoteOption.IsSeriousTrauma ? ', Serious Trauma' : '';
            if (benefit.QuoteOption.TpdAddOn === true) {
                header += benefit.QuoteOption.TpdOwnOccupation ? ', Own OCC' : ', Any OCC';
            }
        } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
            // TPD
            header += benefit.QuoteOption.OwnOccupation ? ', Own OCC' : ', Any OCC';
            header += benefit.QuoteOption.LifeBuyBack ? ', LBB' : '';
        } else if (benefit.UIBenefitId === 6) {
            // Income

            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }

            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }

            header = formatCurrency(benefit.QuoteOption.IpMonthlyCoverAmount, 'en-nz', '$', 'NZD', '1.0-0');
            header += ', ' + benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 7) {
            // Mortgage Cover
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }

            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }
            header += ', ' + benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 9) {
            // Waiver of Premium
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }
            header = benefit.QuoteOption.WeekWaitPeriod.Name;
        } else if (benefit.UIBenefitId === 11 || benefit.UIBenefitId === 12 || benefit.UIBenefitId === 13) {
            // Level Life
            // Level Trauma
            // Level TPD
            if (!benefit.QuoteOption.CalcPeriod) {
                benefit.QuoteOption.CalcPeriod = this.getCalcPeriodList()[0];
            }
            header += ', ' + benefit.QuoteOption.CalcPeriod.Name;
        }

        return header;
    }
  
    quoteResultFilter(allQuoteResults: QuoteResult[]): void {
        allQuoteResults.forEach(q => {
            let filteredQuoteResultList: QuoteResultItem[] = [];
            q.QuoteResultList.forEach(item => {
                if (item.LinkedProvider === null) {
                    filteredQuoteResultList.push(item);
                } else if (
                    item.Provider.ProviderId === 10 &&
                    item.LinkedProvider !== null &&
                    item.LinkedProvider.ProviderId === 1010
                ) {
                    filteredQuoteResultList.push(item);
                }
            });
            q.QuoteResultList = filteredQuoteResultList;
        });
    }
}
