import { Component } from '@angular/core';
import { FactorDetailBaseComponent } from '../factor-detail-base/factor-detail-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { KiwiMonsterResearchService } from 'src/app/service/kiwimonster-research/kiwimonster-research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { SchemePayoutFactorData, YearDataItem } from 'src/app/models/kiwimonster-research/research-payouts.model';

@Component({
  selector: 'app-research-payouts',
  templateUrl: './research-payouts.component.html',
  styleUrls: ['./research-payouts.component.scss']
})
export class ResearchPayoutsComponent extends FactorDetailBaseComponent {
  displayFirstHeaderColumns: string[] = [];
  displaySecondHeaderColumns: string[] = [];
  displayFirstFooterColumns: string[] = [];
  displaySecondFooterColumns: string[] = [];
  displayedColumns: string[] = [];
  allData: SchemePayoutFactorData[] = [];
  years: number[] = [];
  constructor (
    public kiwiMonsterResearchService: KiwiMonsterResearchService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public sysConfig: SysConfigService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(kiwiMonsterResearchService, sharedFunction, loginService, sysConfig, router, route);
  }

  getData(snackBarMessageCode?: string): void {
    this.isLoadingData = true;

    this.kiwiMonsterResearchService.getSchemePayoutFactorData((response) => {
      if (response && response.length > 0) {
        this.allData = response;
        this.kiwiMonsterResearchService.addRatingNameAndColor<SchemePayoutFactorData>(this.allData, "QmRating", true, false);
        this.getYears();
        this.generateColumns();
        this.generateYearData(this.allData, this.years);
        // bind table data
        setTimeout(() => {
          this.bindTableData<SchemePayoutFactorData>(this.allData, ['SchemeName'], 'Schemes Per Page', false);
          if (snackBarMessageCode) {
            this.sharedFunction.openSnackBar(snackBarMessageCode, 'OK', 2000);
          }
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  getYears(): void {
    let currentYear = new Date().getFullYear();
    this.years = [currentYear, currentYear - 1, currentYear - 2];
  }

  generateColumns(): void {
    this.displayFirstHeaderColumns = ['SchemeName']
      .concat(this.years.map(year => year.toString()))
      .concat(['AverageOverSelectedYears', 'QmRating', 'RatingName']);

    this.displaySecondHeaderColumns = this.generateYearColumns(this.years);

    this.displayedColumns = ['SchemeName']
      .concat(this.generateYearColumns(this.years))
      .concat(['AverageOverSelectedYears', 'QmRating', 'RatingName']);

    this.displayFirstFooterColumns = this.displayedColumns;
    this.displaySecondFooterColumns = this.years.map(year => year.toString());
  }

  generateYearColumns(years: number[]): string[] {
    let columns: string[] = [];
    years.forEach((year) => {
      columns.push(`Withdraw${ year }`, `Fum${ year }`, `PayoutRate${ year }`);
    });

    return columns;
  }

  generateCSV(): void {
    let csvContent: string = '';

    // Header Row
    const headers: string[] = ['Scheme Name','Scheme Number'];
    for (let year of this.years) {
      headers.push(`Withdraw ${ year }`, `FUM ${ year }`, `Payout ${ year }`);
    }
    headers.push('Average Over Selected Years', 'QM Rating', 'Rating Name');
    csvContent += headers.join(',') + '\n';

    // Data Rows
    for (let item of this.allData) {
      const row: string[] = [`${ item.SchemeName }`, `${ item.SchemeNumber }`];
      for (let i = 0; i < this.years.length; i++) {
        row.push(
          item.YearData[i]?.Withdraw?.toString() || '',
          item.YearData[i]?.Fum?.toString() || '',
          item.YearData[i]?.PayoutRate.toString() || '',
        );
      }
      row.push(
        item.AverageOverSelectedYears ? item.AverageOverSelectedYears.toString() : '',
        item.QmRating.toString() || '',
        item.RatingName || ''
      );
      csvContent += row.join(',') + '\n';
    }

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'payouts-data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-payouts", "generate csv");
  }

  isValidData(): boolean {
    return true;
  }

  update(): void {
    let updateData = this.allData.filter(item => item.HasChanged);
    if (updateData.length === 0) {
      return;
    }
    this.isLoadingData = true;
    updateData.forEach(item => {
      for (let key in item) {
        if (
          ![
            "QmOfferSchemeId",
            "YearData",
          ].includes(key)
        ) {
          delete item[key];
        }
      }
    });
    this.kiwiMonsterResearchService.updateSchemePayoutFactorDataUrl(updateData, (response) => {
      this.getData('Share-SUCCESS-Updated');
    });

  }

  resetEmptyValue(item: SchemePayoutFactorData, property: string, i: number): void {
    if (!item.YearData[i][property]) {
      item.YearData[i][property] = 0;
    }
  }

  reCalValues(item: SchemePayoutFactorData, i: number): void {
    item.HasChanged = true;
    item.YearData[i].PayoutRate = !item.YearData[i].Fum ? 0 : item.YearData[i].Withdraw / item.YearData[i].Fum;
    item.AverageOverSelectedYears = SchemePayoutFactorData.calculateAverageOverSelectedYears(item);
    SchemePayoutFactorData.calculateQmRatings(this.allData);
    this.kiwiMonsterResearchService.addRatingNameAndColor<SchemePayoutFactorData>(this.allData, "QmRating", true, false);
  }
  
  generateYearData(allData: SchemePayoutFactorData[], years: number[]): void {
    allData.forEach(item => {
      // If YearData is empty, populate it with all years
      if (item.YearData.length === 0) {
        item.YearData = years.map(year => {
          let newYearDataItem = new YearDataItem();
          newYearDataItem.Year = year;
          return newYearDataItem;
        });
      } else {
        // If YearData is not empty, add missing years
        let yearData = item.YearData;

        // Find missing years and create new entries
        let newEntries = years
          .filter(year => !yearData.some(data => data.Year === year))
          .map(year => {
            let newYearDataItem = new YearDataItem();
            newYearDataItem.Year = year;
            return newYearDataItem;
          });

        // Add new entries and sort YearData by Year (descending order)
        item.YearData = yearData.concat(newEntries);
      }
      // Sort YearData by Year (descending order)
      item.YearData.sort((a, b) => b.Year - a.Year);
    });
  }

}
