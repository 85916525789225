<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>Research Adviser Support Factor</h5>
    </li>

    <!-- go back button -->
    <li class='list-group-item'>
      <back-to-categories-button [calculationCode]="currentEvalCategory.CalculationCode"></back-to-categories-button>
    </li>

    <!-- eval category table -->
    <li class="list-group-item border-top-dashed">
      <eval-category-item-table [currentEvalCategory]="currentEvalCategory"></eval-category-item-table>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-end'>
        <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
      </div>
    </li>

    <!-- version detail -->
    <li class="list-group-item border-top-dashed" *ngIf='!isLoadingData'>
      <div class="row">
        <div class="col"><mat-form-field>
            <input matInput placeholder="Version ID:" [(ngModel)]="currentAdviserSupportVersion.VersionId" disabled>
          </mat-form-field></div>
        <div class="col">
          <mat-form-field>
            <input matInput placeholder="Modify Date:" [(ngModel)]="formatDateTime" disabled>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Name:</mat-label>
            <input matInput required placeholder="Name" [(ngModel)]="currentAdviserSupportVersion.VersionName"
              appAutoSelect>
          </mat-form-field>
        </div>
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Description:</mat-label>
            <textarea class="unset-min-height" matInput name="description"
              [(ngModel)]='currentAdviserSupportVersion.Description' rows="2"></textarea>
          </mat-form-field>
        </div>
      </div>
    </li>

    <!-- search -->
    <li class="list-group-item border-top-dashed" *ngIf='!isLoadingData'>
      <div class='input-group my-3'>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
          </span>
        </div>
        <input class="form-control" placeholder="Search Scheme" aria-label="Search"
          (keyup)="textFilter($event.target.value)" appAutoSelect>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed" *ngIf='errorMessage && !isLoadingData'>
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- scheme table -->
    <li class="list-group-item border-top-dashed" *ngIf="allData.length > 0 && !errorMessage && !isLoadingData">
      <div class="text-right mb-3"><button mat-flat-button color="qm-blue" (click)="generateCSV()">Export to
          CSV</button>
      </div>

      <div class="row">
        <div class="col align-self-end">
          <p class='mb-0 text-pumpkin'><strong>Total Survey Response:
              {{currentAdviserSupportVersion.TotalSurveyResponses | number:'1.0-0'}}</strong>
          </p>
        </div>
        <div class="col-auto">
          <p class='mb-0 text-pumpkin'><strong>Max Score: {{maxScore * 100 | number:'1.2-2'}}%</strong></p>
        </div>
      </div>

      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-striped">
          <!-- Scheme Name -->
          <ng-container matColumnDef="SchemeName">
            <th class='align-top' *matHeaderCellDef mat-sort-header>Scheme</th>
            <td class='align-middle' *matCellDef="let item">
              {{item.SchemeName}}
            </td>
            <th class='align-top' *matFooterCellDef>Scheme</th>
          </ng-container>
          <!-- NumberOfResponse -->
          <ng-container matColumnDef="NumberOfResponse">
            <th class='align-top w-280px w-min-280px w-max-280px' *matHeaderCellDef mat-sort-header>Number Of Response</th>
            <td class='align-middle' *matCellDef="let item">
              <input type="number" class='form-control text-right' [ngClass]="{'text-primary': item.HasChanged}"
                     [(ngModel)]="item.NumberOfResponse" (ngModelChange)='item.HasChanged=true; reCalValues()'
                     (blur)="resetEmptyValue(item)" (wheel)="$event.target.blur()" appAutoSelect>
            </td>
            <th class='align-top w-280px w-min-280px w-max-280px' *matFooterCellDef>Number Of Response</th>
          </ng-container>
          <!-- Score -->
          <ng-container matColumnDef="Rating">
            <th class='align-top w-210px w-min-210px w-max-210px' *matHeaderCellDef mat-sort-header>Score</th>
            <td class='align-middle text-right' *matCellDef="let item">
              {{item.Rating * 100 | number:'1.2-2' }}%
            </td>
            <th class='align-top w-210px w-min-210px w-max-210px' *matFooterCellDef>Score</th>
          </ng-container>
          <!-- Rating Name-->
          <ng-container matColumnDef="RatingName">
            <th class='align-top' *matHeaderCellDef>Rating</th>
            <td class='position-rel w-min-120px' *matCellDef="let item">
              <p class='rounded mb-0 px-2 text-center font-weight-bolder qm-text-sm w-110px w-max-110px position-middle' [ngClass]="item.RatingNameBgColor">
                {{item.RatingName}}</p>
            </td>
            <th class='align-top' *matFooterCellDef>Rating</th>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns" class="table-info"></tr>
        </table>
      </div>
    </li>

    <!-- action buttons -->
    <li class='list-group-item border-top-dashed' *ngIf='!isLoadingData'>
      <div class='d-flex justify-content-end'>
        <ng-template *ngTemplateOutlet="actionButtons"></ng-template>
      </div>
    </li>
  </ul>
</div>

<ng-template #actionButtons>
  <div>
    <button mat-flat-button color="qm-pumpkin"
      (click)='updateVersion();loginService.doGoogleTracking("InternalAdminPortal", "kiwimonster-research-advSupport", "update version")'>
      Update
    </button>
  </div>
  <!-- <div>
    <button mat-flat-button color="qm-blue" (click)='openConfirmCreateDialog()'>
      Create
    </button>
  </div> -->
</ng-template>