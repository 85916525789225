<ng-container>
  <ul class='list-group list-group-flush'>

    <ng-container *ngIf='showNoClientError else benefitError'>
      <li class='list-group-item'>
        <div class='alert alert-danger text-center' role='alert'>
          Please enter client details.
        </div>
      </li>
    </ng-container>

    <ng-template #benefitError>
      <ng-container *ngIf='quoteStepBenefit.errorMessage.length > 0'>
        <div class='alert alert-danger mt-4 mx-3 text-center' role='alert'
             *ngFor='let error of quoteStepBenefit.errorMessage'>
          {{error}}
        </div>
      </ng-container>

      <ng-container
                    *ngIf='quoteStepBenefit.errorMessage.length ===0 && quoteStepBenefit.bulkQuoteResultList.length ===0 && !quoteStepBenefit.isLoading'>
        <li class='list-group-item border-0'>
          <div class='alert alert-danger text-center' role='alert'>
            No results.
          </div>
        </li>
      </ng-container>
    </ng-template>


    <ng-container *ngIf='quoteStepBenefit.bulkQuoteResultList.length > 0 && !showNoClientError'>
      <!-- <button mat-icon-button color="warn" (click)='showInfo = !showInfo'>
        <span class="material-icons" aria-label="Config">info</span>
      </button> -->
      <!-- <span *ngIf="showInfo" class='align-middle'> -->
      <ul class='qm-text-sm list-style-asterisk ml-n3'>
        <li>Quote results with a linked provider will not be displayed. Or you can change the product settings.</li>
        <li>Child's Trauma Standalone Cover Amount is set at 50,000.</li>
        <li>The quote results will not be saved if you don't click Crunch/Send Result To My Email button on Benefit Details.
        </li>
      </ul>
      <!-- </span> -->

      <div *ngFor='let quoteResult of quoteStepBenefit.bulkQuoteResultList' class='bulk-quote-result-container'>
        <div class='qm-bg-pumpkin text-white rounded mx-1 my-3 p-2'>Quote {{quoteResult.AppId}}
        </div>

        <ng-container *ngFor='let resultItem of quoteResult.QuoteResultList'>
            <ng-container *ngIf='!resultItem.Provider.IsBankResult'>
              <ng-container
                            *ngIf="resultItem.Provider.TotalPremium > 0 && resultItem.Provider.TotalPremium < 9999999999 ">
                <mat-card class="mb-3 mx-1 border">
                  <div class='row justify-content-between text-pumpkin parent-container'>
                    <div class='col-2'>
                      <img src="{{resultItem.Provider.ProviderLogoUrl}}?t={{timestamp}}" class='ml-3 mb-3'
                           alt="{{resultItem.Provider.ProviderName}}" />
                    </div>
                    <div class='col-auto text-right'>
                      <h6>Policy Fee:</h6>
                      <strong class='text-right'>{{resultItem.Provider.PolicyFee | currency}}</strong>
                    </div>

                    <!-- show provider special discount info -->
                    <div class='col-auto text-right'
                         *ngIf='resultItem.Provider.SpecialProviderDiscountCode && resultItem.Provider.SpecialProviderDiscountValue > 0'>
                      <h6>{{resultItem.Provider.SpecialProviderDiscountCode}}:</h6>

                      <strong>
                        {{resultItem.Provider.SpecialProviderDiscountValue
                        | currency}}
                      </strong>

                    </div>
                    <!-- show provider special discount info -->

                    <div class='col-auto text-right'
                         *ngIf='resultItem.Provider.Commission !== null && resultItem.Provider.Commission > 0'>
                      <h6>Total Premium ({{getFrequencyName()}}):</h6>
                      {{resultItem.Provider.TotalPremium - resultItem.Provider.Commission | currency:'NZD'}}
                    </div>
                    <div class='col-auto text-right'
                         *ngIf='resultItem.Provider.Commission === null || resultItem.Provider.Commission <= 0'>
                      <h6>Total Premium ({{getFrequencyName()}}):</h6>
                      {{resultItem.Provider.TotalPremium | currency}}
                    </div>
                    <div class='col-auto text-right'>
                      <h6>Quote Software Total Premium:</h6>
                      <mat-form-field class='small-input text-right'>
                        <input matInput
                               (keyup)="calculateCompare(resultItem.Provider.TotalPremium - resultItem.Provider.Commission, $event.target.value, $event)"
                               type='number' value='0'
                               *ngIf='resultItem.Provider.Commission !== null && resultItem.Provider.Commission > 0'
                               appAutoSelect>
                        <input matInput
                               (keyup)="calculateCompare(resultItem.Provider.TotalPremium, $event.target.value, $event)"
                               type='number' value='0'
                               *ngIf='resultItem.Provider.Commission === null || resultItem.Provider.Commission <= 0'
                               appAutoSelect>
                        <span matPrefix>$&nbsp;</span>
                      </mat-form-field>
                    </div>
                    <div class='col-auto text-right'>
                      <h6> Compare: </h6>
                      <span class='px-3 py-1 rounded compare-value-wrapper'>0%</span>
                    </div>
                  </div>
                  <div class='table-responsive'>
                    <table class="table table-hover bulk-quote-result-table mb-0">
                      <thead class='thead-dark border-top'>
                        <tr>
                          <th class='border-0 py-1'>Benefit</th>
                          <th class='border-0 py-1'>Product Name</th>
                          <th class='border-0 py-1'>Cover Detail</th>
                          <th class='border-0 py-1'>QM Premium</th>
                          <th class='border-0 py-1'>Quote Software Premium</th>
                          <th class='border-0 py-1'>Compare</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor='let result of resultItem.Result'>
                          <!-- client detail start -->
                          <tr *ngIf='result.IsChild !== true' class='no-hover'>
                            <td colspan='6' class='py-2'>
                              <span class='my-1 font-weight-bolder text-info'>
                                {{result.FirstName}} {{result.LastName}},
                              </span>
                              <span class='my-1 font-weight-bolder text-info'> {{result.Age}} year old,</span>
                              <span *ngIf='result.Gender === 1' class='my-1 font-weight-bolder text-info'>
                                Male,</span>
                              <span *ngIf='result.Gender === 2' class='my-1 font-weight-bolder text-info'>
                                Female,</span>
                              <span *ngIf='result.Smoker !== true' class='my-1 font-weight-bolder text-info'>
                                Non-Smoker,</span>
                              <span *ngIf='result.Smoker === true' class='my-1 font-weight-bolder text-info'>
                                Smoker,</span>
                              <span class='my-1 font-weight-bolder text-info'> Class
                                {{result.OccupationId.Value}}</span>
                            </td>
                          </tr>
                          <tr *ngIf='result.IsChild === true' class='no-hover'>
                            <td colspan='6' class='py-2'>
                              <span class='my-1 font-weight-bolder text-info'>
                                {{result.FirstName}} {{result.LastName}},
                              </span>
                              <span class='my-1 font-weight-bolder text-info'> {{result.Age}} year old,</span>
                              <span *ngIf='result.Gender === 1' class='my-1 font-weight-bolder text-info'> Male</span>
                              <span *ngIf='result.Gender === 2' class='my-1 font-weight-bolder text-info'>
                                Female</span>
                            </td>
                          </tr>
                          <!-- client detail end -->
                          <ng-container *ngFor='let benefit of result.BenefitList'>
                            <!-- <ng-container *ngIf='benefit.LinkedToProviderId === null'> -->
                            <tr *ngFor='let product of benefit.ProductEntityList' class='parent-container'>
                              <td class='pb-0'>
                                <span class='font-weight-bold'>{{benefit.BenefitName}}</span>
                              </td>
                              <td class='pb-0'>
                                <span class='d-block font-weight-bold'>{{product.ProductName}}</span>
                                <span class='font-weight-light font-italic qm-text-sm '
                                      [innerHTML]='product.ProductDescription'></span>
                              </td>
                              <td class='pb-0'>
                                <span class='font-weight-bold'>{{product.CoverDetail}}</span>
                              </td>
                              <td class='pb-0'>
                                <span class='font-weight-bold'>
                                  {{product.Premium | currency}}
                                </span>
                              </td>
                              <td class='font-weight-bold pb-0'>
                                <mat-form-field class='small-input text-right w-auto'>
                                  <input matInput
                                         (keyup)="calculateCompare(product.Premium, $event.target.value, $event)"
                                         type='number' value='0' appAutoSelect>
                                  <span matPrefix>$&nbsp;</span>
                                </mat-form-field>
                              </td>
                              <td class='font-weight-bold pb-0'>
                                <span class='px-3 py-1 rounded compare-value-wrapper'>0%</span>
                              </td>
                            </tr>
                            <!-- </ng-container> -->
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </mat-card>
              </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngFor='let resultItem of quoteResult.QuoteResultList'>
            <ng-container *ngIf='!resultItem.Provider.IsBankResult'>
              <!-- TotalPremium === 9999999999 or TotalPremium <=0 start -->
              <ng-container
                            *ngIf="resultItem.Provider.TotalPremium === 9999999999 || resultItem.Provider.TotalPremium <= 0">
                <mat-card class="mb-3 mx-1 border">
                  <div class='row justify-content-between text-pumpkin'>
                    <div class='col-2'>
                      <img src="{{resultItem.Provider.ProviderLogoUrl}}?t={{timestamp}}" class='ml-3 mb-3'
                           alt="{{resultItem.Provider.ProviderName}}" />
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-12 ml-3'><span>Client Detail:</span></div>
                    <ng-container *ngFor='let result of resultItem.Result'>
                      <div class='col-12 ml-3'>
                        <span class='my-1 font-weight-bolder text-info'>
                          {{result.FirstName}} {{result.LastName}},
                        </span>
                        <span class='my-1 font-weight-bolder text-info'> {{result.Age}} year old,</span>
                        <span *ngIf='result.Gender === 1' class='my-1 font-weight-bolder text-info'> Male</span>
                        <span *ngIf='result.Gender === 2' class='my-1 font-weight-bolder text-info'>
                          Female</span>
                        <ng-container *ngIf='result.IsChild !== true'>
                          <span *ngIf='result.Smoker !== true' class='my-1 font-weight-bolder text-info'>
                            Non-Smoker,</span>
                          <span *ngIf='result.Smoker === true' class='my-1 font-weight-bolder text-info'>
                            Smoker,</span>
                          <span class='my-1 font-weight-bolder text-info'> Class
                            {{result.OccupationId.Value}}</span>
                        </ng-container>
                      </div>
                    </ng-container>
                    <div class='col-12 ml-3 mt-3'><span>Error Message:</span></div>
                    <div class='col-12 ml-3' *ngFor='let e of resultItem.ErrorMessages'>
                      <span class="text-danger">
                        {{e.ExtValue}} - {{e.Message}}
                      </span>
                    </div>
                  </div>
                </mat-card>
              </ng-container>
              <!-- TotalPremium === 9999999999 or TotalPremium <=0 end -->
            </ng-container>
        </ng-container>
      </div>

    </ng-container>
  </ul>
</ng-container>