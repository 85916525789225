import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SelectItemStruct } from 'src/app/models/select.item';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog/confirm-message-dialog.service';
import { QueryBulkQuote } from 'src/app/models/bulk-quote-data.model';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';

@Component({
  selector: 'bulk-quote-dashboard',
  templateUrl: './bulk-quote-dashboard.component.html',
  styleUrls: ['./bulk-quote-dashboard.component.scss']
})
export class BulkQuoteDashboardComponent implements OnInit {
  @Output() changeSelectedIndex = new EventEmitter();
  displayBulkQuotes: SelectItemStruct[] = [];
  textFilterValue: string = '';
  allSavedBulkQuotes: SelectItemStruct[] = [];
  selectedBulkQuotesCount: number = 0;
  selectedBulkQuotesStringId: string[] = [];
  selectedBulkQuotesName: string[] = [];
  errorMessage: string = '';
  constructor (
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public quoteService: QuoteService,
    public quoteStepClient: QuoteStepClient,
    public quoteStepBenefit: QuoteStepBenefit,
    private confirmDialog: ConfirmMessageDialogService,
  ) { }

  ngOnInit(): void {
    // clear data and set a new savedQueryBulkQuote
    this.quoteService.savedQueryBulkQuote = new QueryBulkQuote();
    this.quoteStepClient.clientForm = null;
    this.quoteStepBenefit.savedQueryBulkQuote = null;
    this.getAllSavedBulkQuotes();
  }

  getAllSavedBulkQuotes(): void {
    this.quoteService.showDinoLoading();
    this.quoteService.getSavedBulkQuotes((response) => {
      if (response && response.length > 0) {
        this.allSavedBulkQuotes = response;
        this.doSearch(this.textFilterValue);
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.quoteService.closeDinoLoading();
    });
  }

  getSelectedBulkQuotes(): void {
    this.selectedBulkQuotesCount = this.allSavedBulkQuotes.filter(bq => bq.Selected).length;
    this.selectedBulkQuotesStringId = this.allSavedBulkQuotes
      .filter(bq => bq.Selected)
      .map(bq => bq.StringValue1);
    this.selectedBulkQuotesName = this.allSavedBulkQuotes
      .filter(bq => bq.Selected)
      .map(bq => bq.StringValue2);
  }

  getBulkQuoteDetail(bulkQuote: SelectItemStruct): void {
    this.quoteService.showDinoLoading();
    this.quoteService.getSavedBulkQuoteDetail(bulkQuote.StringValue1, (response) => {
      // set a existing savedQueryBulkQuote
      response.SavedBenefits = response.QuoteDatas[0].PeopleEntity[0].BenefitList;
      this.quoteService.savedQueryBulkQuote = response;
      this.changeSelectedIndex.emit(1);
    });
  }

  runSavedBulkQuotes(): void {
    if (this.selectedBulkQuotesStringId.length > 0) {
      this.quoteService.showDinoLoading();
      this.quoteService.runSavedBulkQuotes(this.selectedBulkQuotesStringId, (response) => {
        if (response && response.MessageCode === 200) {
          // display message dialog
          let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
          messageSetting.Title = 'Notice';
          messageSetting.Message = response.Message;
          messageSetting.NeedYesBtn = false;
          messageSetting.NoBtnName = 'OK';
          this.confirmDialog.confirm(messageSetting).subscribe(() => {
            this.getAllSavedBulkQuotes();
          });
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
        }
        this.selectedBulkQuotesCount = 0;
      });
    }
  }

  startNewBulkQuote(): void {
    this.changeSelectedIndex.emit(1);
  }
  
  deleteBulkQuotes(): void {
    if (this.selectedBulkQuotesStringId.length > 0) {
      this.quoteService.showDinoLoading();
      this.quoteService.deleteBulkQuotes(this.selectedBulkQuotesStringId, (response) => {
        if (response && response.length > 0) {
          this.allSavedBulkQuotes = response;
          this.displayBulkQuotes = this.allSavedBulkQuotes;
          this.doSearch(this.textFilterValue);
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
        }
        this.quoteService.closeDinoLoading();
        this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
        this.selectedBulkQuotesCount = 0;
      });
    }
  }
  
  openConfirmCancelDialog() {
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
    messageSetting.Message = `<h5>Are you sure to <span class="text-danger">delete</span> the quote(s)?</h5><ul> ${ this.selectedBulkQuotesName.map(name => `<li class="text-capitalize">${ name }</li>`).join('') } </ul> `;
    messageSetting.NoBtnName = 'NO';
    this.confirmDialog.confirm(messageSetting).subscribe((response) => {
      if (response.ReturnValue === true) {
        this.deleteBulkQuotes();
      }
    });
  }
  
  doSearch(searchText: string): void {
   this.textFilterValue = searchText.toLowerCase().trim();
    if (this.textFilterValue) {
      this.displayBulkQuotes = this.allSavedBulkQuotes.filter(q => q.StringValue2.toLowerCase().trim().includes(this.textFilterValue));
    } else {
      this.displayBulkQuotes = this.allSavedBulkQuotes;
    }

    if (this.displayBulkQuotes.length === 0) {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
    } else {
      this.errorMessage = '';
    }
  }
}
